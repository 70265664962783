export default function scrollArrowHub() {
    $(document).ready(() => {
        if (!$('.scrollButton')) return;
        $('.scrollButton').on('click', (e) => {
            const divParent = $(e.target).parent('.hub-content__wrapper');
            const divHeight = divParent.height();
            const divOffSet = divParent.offset().top;
            $('html, body').animate({ scrollTop: divHeight + divOffSet }, 'slow');
        });
    });
}
