import AOS from 'aos';
import objectFitImages from 'object-fit-images';
import Carousel from 'common/components/carousel';
import Hero from 'common/experience/components/assets/hero';
import WidgetOne from 'common/experience/components/assets/widgetOne';
import WidgetFour from 'common/experience/components/assets/widgetFour';
import Videos from 'common/components/videos';
import EditorialPopup from 'common/experience/components/editorial_assets/editorialPopup';
import RemoveExperienceLayer from 'common/experience/components/editorial_assets/removeExperienceLayer';
import scroll from './components/hero_slider';
import linkFullImage from './components/layouts/linkFullImage';
import scrollArrowHub from './components/scrollArrowHub';
import CarouselPlayer from './components/carouselPlayer';
import AutoplayCarousel from './experience/autoplayCarousel';
import loyaltyModal from './components/loyaltyModal';
import LoyaltyLevels from './experience/loyaltyLevels';

document.addEventListener('DOMContentLoaded', () => {
    const $fitImages = $('.home-widget-hero img');

    const carousel = new Carousel();
    const one = new WidgetOne();
    const four = new WidgetFour();
    const hero = new Hero();
    const videos = new Videos();
    const removeExperienceLayer = new RemoveExperienceLayer();
    const editorialPopup = new EditorialPopup();
    const carouselPlayer = new CarouselPlayer();
    const autoplay = new AutoplayCarousel();
    const loyaltyLevels = new LoyaltyLevels();

    objectFitImages($fitImages);
    carousel.init();
    one.init();
    four.init();
    hero.init();
    videos.init();
    removeExperienceLayer.init();
    editorialPopup.init();
    carouselPlayer.init();
    autoplay.init();
    loyaltyLevels.init();

    AOS.init({
        duration: 600,
        easing: 'liujo',
        once: true,
    });
    scroll();
    linkFullImage();
    scrollArrowHub();
    loyaltyModal();
});
