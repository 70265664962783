export default class WidgetOne {
    constructor() {
        this.$widget = $('.home-widget-1');
        this.$items = this.$widget.find('.home-widget-1__tile');
        this.buttonsSelector = '.home-widget-1__list-element a';
        this.$buttons = this.$widget.find(this.buttonsSelector);
    }

    init() {
        this.$widget.on('click', this.buttonsSelector, (e) => {
            if (!window.breakpoints.atLeast('sm')) {
                return;
            }
            this.carousel(e);
        });
    }

    carousel(clickedElement) {
        clickedElement.preventDefault();

        const $current = $(clickedElement.currentTarget);
        const $target = $current.attr('data-carousel');

        if (!$($target).length) {
            return;
        }

        this.$buttons.not($current).removeClass('active');
        $current.addClass('active');

        const $hide = this.$items.not($($target));
        const $show = $($target);

        $hide.addClass('leaving');
        $show.removeClass('leaving');

        setTimeout(() => {
            $hide.closest('.home-widget-1__tile-wrapper').hide();
            $hide.removeClass('leaving');
            $show.closest('.home-widget-1__tile-wrapper').show();
        }, 520);
    }
}
