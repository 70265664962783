import { debounce } from 'lodash';

export default class editorialPopup {

    constructor() {
        this.outerHeight = 0;
        this.offsetOld = 0;
    }

    init() {
        this.events();
    }

    events() {
        const debouncedResize = debounce(() => {
            const $modal = $('.modal.show.editorial-popup-modal');
            if ($modal.length >= 1) {
                this.setDimensions($modal);
            }
        }, 300);

        $(window).on('resize', debouncedResize);

        $('.hotspot-container').on('click', e => {
            const self = $(e.currentTarget);
            this.$modal = $(self.data('target')).hasClass('editorial-popup-modal') ? $(self.data('target')) : false;
            if (this.$modal) {
                this.setDimensions();
                this.bindScroll();
            }
        });
    }

    bindScroll() {
        this.$modal.find('.modal-fixed-body-inner').on('scroll', e => {
            const isDesktop = window.breakpoints.atLeast('md');
            if (isDesktop) {
                const self = $(e.currentTarget);
                const $indexSelector = self.siblings('.index-selector').find('.index-selector-item');
                const $modalBody = self.find('.modal-body');
                const selfOffsetTop = self.offset().top;
                const modalBodyOffsetTop = $modalBody.offset().top;
                const activeIndex = Math.round((selfOffsetTop - modalBodyOffsetTop) / this.outerHeight);
                $indexSelector.removeClass('active');
                $indexSelector.eq(activeIndex).addClass('active');
            }
        });
    }

    setDimensions() {
        const isDesktop = window.breakpoints.atLeast('md');
        if (isDesktop) {
            this.outerHeight = 0;
            this.$modal.addClass('d-flex');
            this.$modal.find('.product').each((i, elem) => {
                this.outerHeight = $(elem).outerHeight() > this.outerHeight ? $(elem).outerHeight() : this.outerHeight;
                this.outerHeight = parseInt(this.outerHeight, 0);
            });
            $('.modal-fixed-body, .modal-fixed-body-inner').height(this.outerHeight);
            this.$modal.find('.product').each((i, elem) => {
                const paddingY = ((this.outerHeight - $(elem).outerHeight()) / 2) + 12;
                $(elem).css('padding-top', paddingY).css('padding-bottom', paddingY);
            });
            this.$modal.removeClass('d-flex');
        } else {
            $('.modal-fixed-body, .modal-fixed-body-inner').height('');
            $('.modal-fixed-body .product').css('padding-top', '').css('padding-bottom', '');
        }
    }
}
