import Swiper from 'swiper/swiper-bundle';

const SELECTORS = {
    widget: '.autoplay-carousel',
};

export default class AutoplayCarousel {
    constructor() {
        this.$widgets = $(SELECTORS.widget);
        this.isFirstRender = true;
    }

    init() {
        this.handleAutoplay();
    }

    initSlider(slider, delay) {
        this.swiper = new Swiper(`${slider} .swiper`, {
            slidesPerView: 1.5,
            spaceBetween: 15,
            slidesOffsetBefore: 0,
            allowTouchMove: true,
            freeMode: false,
            mousewheel: false,
            watchOverflow: true,
            autoplay: {
                delay,
                disableOnInteraction: false,
            },
            loop: true,
            breakpoints: {
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 35,
                },
            },
        });
    }

    handleAutoplay() {
        for (const widget of this.$widgets) {
            const interval = $(widget).data('autoplayInterval');
            const target = `#${$(widget).attr('id')}`;
            this.initSlider(target, interval);
        }
    }
}
