export default class RemoveExperienceLayer {
    constructor() {
        jQuery.fn.reverse = [].reverse;
    }

    init() {
        $('.js-remove').reverse().each((i, v) => {
            const self = $(v);
            const content = self.html();
            self.replaceWith(content);
        });
    }
}
