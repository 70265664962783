import { throttle } from 'lodash';
import prepare from 'aos/src/js/helpers/prepare';

export default class Videos {
    constructor() {
        this.$videos = $('body').find('video');
        this.videos = this.$videos.toArray().map((node) => ({ node }));
        this.playerClass = 'video__player';
        this.isPlayingClass = 'is-playing';
        this.buttonVideoVolume = 'button.video-volume';
        this.buttonVideoPlay = 'button.experience-video__controls__play';

        this.scrollOptions = {
            offset: 60,
            throttleDelay: 200,
        };
    }

    // enable or disable audio
    setVideoAudio($video, video, isButton) {
        const $currentTarget = $video.parents().children(this.buttonVideoVolume);
        const $videoVolumeIconMute = $currentTarget.find('.isicon:visible');
        const $videoVolumeIconUp = $currentTarget.find('.isicon:hidden');
        if ($videoVolumeIconMute.length && $videoVolumeIconUp.length) {
            $videoVolumeIconMute.css('display', 'none');
            $videoVolumeIconUp.css('display', 'block');
        }
        if (video.muted) {
            if (isButton) {
                $video.prop('volume', 1.0);
            } else {
                $video.prop('volume', 0.33);
            }
            $video.prop('muted', false);
        } else {
            $video.prop('muted', true);
        }
    }

    // icon play or pause
    setVideoPlay($video) {
        const $currentTarget = $video.parents().children(this.buttonVideoPlay);
        const $videoPlay = $currentTarget.find('.isicon:visible');
        const $videoPause = $currentTarget.find('.isicon:hidden');
        if ($videoPlay.length && $videoPause.length) {
            $videoPlay.css('display', 'none');
            $videoPause.css('display', 'block');
        }
    }


    videoButtonPlayEvent() {
        this.$videos.each((i, el) => {
            const $video = $(el);
            const playButton = $video.parents().children(this.buttonVideoPlay);
            if (playButton) {
                playButton.on('click', () => $video.click());
                $video.on('pause', () => {
                    this.setVideoPlay($video);
                });
                $video.on('play', () => {
                    this.setVideoPlay($video);
                });
            }
        });
    }

    init() {
        if (!this.$videos.length) return;

        this.videoInitialization();
        this.videoEvents();
        this.autoplayVideosOnScroll();
        this.videoButtonPlayEvent();
    }

    videoInitialization() {
        if (!this.$videos.length) return;


        this.$videos.each((i, el) => {
            const video = $(el);
            const videoSource = video.find('source').get(0);
            const $source = $('<source>');
            let poster = video.data('poster') || '';
            let source = $(videoSource).data('src') || '';

            if (!window.breakpoints.atLeast('sm')) {
                if (video.data('poster-mobile')) {
                    poster = video.data('poster-mobile');
                }
                if ($(videoSource).data('src-mobile')) {
                    source = $(videoSource).data('src-mobile');
                }
            }

            video.attr('poster', poster);
            video.prop('muted', true);
            video.parent().find('.isicon--volume-mute-fill__new').css('display', 'block');
            video.parent().find('.isicon--volume-up-fill__new').css('display', 'none');
            video.parent().find('.isicon--volume-mute-fill').css('display', 'block');
            video.parent().find('.isicon--volume-up-fill').css('display', 'none');
            $source.attr('src', source).attr('type', 'video/mp4');
            video.append($source);
        });
    }

    videoEvents() {
        this.$videos.each((i, el) => {
            const $video = $(el);
            if (!$video.length) {
                return;
            }
            const video = $video.get(0);

            // video pause click (also enable audio if is active for play video)
            $video.on('click', () => {
                if (video.paused) {
                    if (video.muted && !$video.data('enable-audio-only-with-button')) {
                        this.setVideoAudio($video, video, false);
                    }
                    video.play().then(() => {
                        $video.data('has-played', true);
                        if ($video.parent().hasClass(this.playerClass)) {
                            $video.parent().addClass(this.isPlayingClass);
                        } else {
                            $video.addClass(this.isPlayingClass);
                        }
                    }).catch((err) => {
                        console.warn(err);
                    });
                } else {
                    video.pause();
                    if ($video.parent().hasClass(this.playerClass)) {
                        $video.parent().removeClass(this.isPlayingClass);
                    } else {
                        $video.removeClass(this.isPlayingClass);
                    }
                }
            });

            // add audio enable event on volume button icons
            $video.parents().children(this.buttonVideoVolume).on('click', () => {
                if ($video.is(':visible')) {
                    this.setVideoAudio($video, video, true);
                }
            });
        });
    }

    autoplayVideosOnScroll() {
        /**
         * Handle scroll event to play videos on scroll
         */
        const $videos = prepare(this.videos, this.scrollOptions);

        window.addEventListener('scroll', throttle(() => {
            this.handleScroll($videos);
        }, this.scrollOptions.throttleDelay));
    }

    handleScroll($videos) {
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;

        /**
         * Check all registered elements positions
         * and animate them on scroll
         */
        $videos.forEach((el) => {
            if (scrollTop + windowHeight > el.position && !$(el.node).hasClass('not-autoplay')) {
                if (el.node.paused && !$(el.node).data('has-played')) {
                    $(el.node).prop('muted', true);
                    el.node.play().then(() => {
                        $(el.node).data('has-played', true);
                    }).catch((err) => {
                        console.warn(err);
                    });
                }
            }
        });
    }
}
