import 'jquery-parallax.js';

export default class WidgetFour {
    constructor() {
        this.$widget = $('.home-widget-4');
    }

    init() {
        if (!this.$widget.length) return;

        this.parallaxImage();
    }

    /**
     *
     * @param {jQuery} $widget - DOM element for current widget
     */
    getImgSrc($widget) {
        let source = '';
        if (window.breakpoints.atLeast('sm')) {
            source = $widget.attr('data-image-src');
        } else {
            source = $widget.attr('data-image-mobile-src');
        }
        return source;
    }

    parallaxImage() {
        this.$widget.each((i, el) => {
            const source = this.getImgSrc($(el));

            $(el).parallax({
                imageSrc: source,
                speed: 0.7,
            });
        });
    }
}
