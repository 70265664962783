export default class CarouselPlayer {
    constructor() {
        this.carouselId = $('.squareSlider').attr('id');
        this.carousel = $('.squareSlider');
        this.playButton = $('.squareSlider__controls__play');
    }

    init() {
        this.playButton.on('click', (e) => {
            e.preventDefault();
            const carousel = $(e.currentTarget).parents('.squareSlider');
            const carouselId = carousel.attr('id');
            const playIcon = $(e.currentTarget).find('.play');
            const pauseIcon = $(e.currentTarget).find('.pause');
            this.playCarousel.bind(this);
            this.playCarousel(carouselId, playIcon, pauseIcon);
        });
        this.carousel.each((index, el) => {
            const autoRun = $(el).attr('data-autorun');
            if (autoRun === 'true') {
                $(el).carousel('cycle');
            }
        });
        this.linkFullImage();
        $(window).on('resize', () => {
            // on resize prevent reinit carousel
            this.carousel.each((index, el) => {
                const playIcon = $(el).find('.play');
                this.resizePreventCarousel.bind(this);
                this.resizePreventCarousel(el, playIcon);
            });
        });
    }

    playCarousel(id, playIcon, pauseIcon) {
        if (!playIcon.hasClass('d-none')) {
            $(`#${id}`).carousel('next');
            $(`#${id}`).carousel('cycle');
            playIcon.addClass('d-none');
            pauseIcon.removeClass('d-none');
        } else {
            $(`#${id}`).carousel('pause');
            pauseIcon.addClass('d-none');
            playIcon.removeClass('d-none');
        }
    }

    linkFullImage() {
        document.querySelectorAll('.square-slide.linkFullImage').forEach((el) => {
            const linkTo = el.getAttribute('data-link-to');
            const placeholder = /^javascript:;$/;
            if (!!linkTo && !placeholder.test(linkTo) && linkTo !== 'null') {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    location.href = linkTo;
                });
                // stop propagation of the click event on the button inside the el
                $(el).find('button, a').click((e) => {
                    e.stopPropagation();
                });
            } else {
                $(el).css('cursor', 'auto');
            }
        });
    }
    resizePreventCarousel(slider, playIcon) {
        if (!playIcon.hasClass('d-none')) {
            $(slider).carousel('pause');
        }
    }
}
