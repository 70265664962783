export default class LoyaltyLevels {
    constructor() {
        this.$categoryButton = $('.loyalty-levels-banner__navigation a');
        this.$productCategoryComponent = $('.loyalty-levels-banner__component');
        this.$mobileLoyalyNavigation = $('.loyalty-levels-banner__section .mobile-menu');
    }

    init() {
        this.events();
        const color = $('.loyalty-levels-banner__navigation li:first a').data('color');
        $('.loyalty-levels-banner').css('--loyalty-border-color', color);
        const $firstSection = $($('.loyalty-levels-banner__section')[0]);
        const $firstbutton = $firstSection.find('.mobile-menu');
        $firstbutton.addClass('no-rotate');
        this.showCategoryComponent($firstbutton.data('id'));
        $firstbutton.find('p:first-child').addClass('color-underline');
    }

    events() {
        this.$categoryButton.on('click', this.handleCategoryClick.bind(this));
        this.$mobileLoyalyNavigation.on('click', this.handleMobileNavigationClick.bind(this));
    }

    handleCategoryClick(e) {
        e.preventDefault();
        const $target = $(e.currentTarget);
        const categoryId = $target.data('id');
        const color = $target.data('color');
        this.$categoryButton.removeClass('active');
        $('.loyalty-levels-banner').css('--loyalty-border-color', color);
        $target.addClass('active');
        this.showCategoryComponent(categoryId);
    }

    showCategoryComponent(categoryId) {
        const $target = this.$productCategoryComponent.filter(`[data-id="${categoryId}"]`);
        this.$productCategoryComponent.css('display', 'none');
        $target.css('display', 'flex');
    }

    handleMobileNavigationClick(e) {
        e.preventDefault();
        const $target = $(e.currentTarget);
        const color = $target.data('color');
        $('.loyalty-levels-banner').css('--loyalty-border-color', color);
        if ($target.hasClass('no-rotate')) {
            this.$mobileLoyalyNavigation.removeClass('no-rotate');
            this.$mobileLoyalyNavigation.find('p').removeClass('color-underline');
            this.$productCategoryComponent.css('display', 'none');
            return;
        }
        this.$mobileLoyalyNavigation.removeClass('no-rotate');
        this.$mobileLoyalyNavigation.find('p').removeClass('color-underline');
        $target.addClass('no-rotate');
        $target.find('p:first-child').addClass('color-underline');
        const categoryId = $target.data('id');
        this.showCategoryComponent(categoryId);
    }
}
