export default function linkFullImage() {
    document.querySelectorAll('.hero-slide__media-link').forEach((el) => {
        const linkTo = el.getAttribute('data-link-to');
        const placeholder = /^javascript:;$/;
        if (!!linkTo && !placeholder.test(linkTo) && linkTo !== 'null') {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                location.href = linkTo;
            });
            // stop propagation of the click event on the button inside the el
            $(el).find('button, a').click((e) => {
                e.stopPropagation();
            });
        } else {
            $(el).css('cursor', 'auto');
        }
    });
}
