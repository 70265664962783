export function fixHeightHeroCarousel() {
    const $preHeaderHeight = $('.pre-header').length ? $('.pre-header').eq(0).outerHeight() : 0;
    const $pageHeaderHeight = $('.page-header').length ? $('.page-header').outerHeight() : 0;
    const totalHeight = $preHeaderHeight + $pageHeaderHeight;
    $('.new-hero-slider .hero-slider__slide').css('height', `calc(100vh - ${totalHeight}px)`);
}

export default function scroll() {
    $(document).ready(() => {
        if (!$('.scrollButtonHero')) return;
        $('.scrollButtonHero').on('click', (e) => {
            const divParent = $(e.target).parent('.carousel');
            const divHeight = divParent.height();
            const divOffSet = divParent.offset().top;
            $('html, body').animate({ scrollTop: divHeight + divOffSet }, 'slow');
        });

        /* fixHeightHeroCarousel(); For fix 3354 iPhone15 Chrome bug*/
    });
}
