export default class Hero {
    constructor() {
        this.$widget = $('.home-widget-hero');
        this.$video = this.$widget.find('video');
        this.$toggle = this.$widget.find('.home-widget-hero__play-video');
        this.$bar = this.$widget.find('.home-widget-hero__bar');
        this.state = {
            hasLoaded: false,
        };
    }

    init() {
        if (!this.$video.length) return;

        if (this.$video.hasClass('home-widget-hero__responsive-video')) {
            this.loadAndPlayVideo();
        } else {
            this.events();
        }
    }

    loadAndPlayVideo() {
        if (!this.state.hasLoaded) {
            const videoSource = this.$video.find('source').get(0);
            const $source = $('<source>');
            let poster = this.$video.data('poster') || '';
            let source = $(videoSource).data('src') || '';

            if (!window.breakpoints.atLeast('sm')) {
                if (this.$video.data('poster-mobile')) {
                    poster = this.$video.data('poster-mobile');
                }
                if ($(videoSource).data('src-mobile')) {
                    source = $(videoSource).data('src-mobile');
                }
            }

            this.$video.attr('poster', poster);
            $source.attr('src', source).attr('type', 'video/mp4');
            this.$video.append($source);
            this.state.hasLoaded = true;
        }

        this.$video.get(0).play();
    }

    events() {
        this.$video.on('ended', () => {
            this.$video.fadeOut();
            this.$bar.fadeIn();
        });

        this.$video.on('play', () => {
            this.$video.fadeIn();
            this.$bar.fadeOut();
            this.$toggle.blur();
        });

        this.$video.on('click', (e) => {
            const video = e.currentTarget;
            if (video.paused) {
                this.$bar.fadeOut();
            } else {
                this.$bar.fadeIn();
            }
        });

        this.$toggle.on('click', this.loadAndPlayVideo.bind(this));
    }
}
