import Hammer from 'hammerjs';

export default class Carousel {
    constructor() {
        this.$carousel = $('#carousel');
        this.handleNav = this.handleNav.bind(this);
        this.animating = false;
    }

    events() {
        $(document).on('carousel:prev', () => {
            this.handleNav(-1);
        });

        $(document).on('carousel:next', () => {
            this.handleNav(1);
        });
    }

    handleNav(dir) {
        if (this.animating) {
            return;
        }

        this.animating = true;

        this.$carousel.attr('data-direction', dir === 1 ? 'right' : 'left');

        const $items = this.$carousel.find('.col .item');
        const total = $items.length;
        // on desktop, jump forward 1, 2, or 3 items
        const multiplier = Math.min(Math.max((total - 3), 1), 3);
        const jump = window.breakpoints.atLeast('md') ? dir * multiplier : dir;

        $items.each((i, el) => {
            const $item = $(el).removeClass('animate');
            const $next = $items.eq((i + jump) % total);

            if (dir === 1) {
                const $productToClone = $next
                    .find('.product')
                    .first()
                    .clone();
                $item.append($productToClone);
            } else {
                const $productToClone = $next
                    .find('.product')
                    .last()
                    .clone();
                $item.prepend($productToClone);
            }

            setTimeout(() => {
                $item.addClass('animate');
            }, 34);
        });

        // Remove images
        setTimeout(() => {
            $items.each((i, el) => {
                const $item = $(el);

                if (dir === 1) {
                    $item.find('.product:not(:last)').remove();
                } else {
                    $item.find('.product:not(:first)').remove();
                }

                $item.removeClass('animate');

                this.animating = false;
            });
        }, 1000);
    }

    init() {
        if (!this.$carousel.length) return;

        this.events();

        $('body').on('click', '.controls button[data-action="prev"]', () => {
            $(document).trigger('carousel:prev');
        });

        $('body').on('click', '.controls button[data-action="next"]', () => {
            $(document).trigger('carousel:next');
        });

        const hammer = new Hammer(this.$carousel.get(0));

        hammer.on('swipeleft', () => {
            $(document).trigger('carousel:next');
        });

        hammer.on('swiperight', () => {
            $(document).trigger('carousel:prev');
        });
    }
}
