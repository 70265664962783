export default function loyaltyModal() {
    $(document).ready(() => {
        const modalShown = localStorage.getItem('modalShown');

        if (modalShown) {
            $('.experience-layouts-loyalty_modal').css('pointer-events', 'none');
        }

        const modal = document.getElementById('loyaltyModal');
        const overlayModal = document.getElementsByClassName('overlay-modal-loyalty');
        /* const timeoutValue = modal.getAttribute('data-timeout'); */

        /* $('.experience-layouts-loyalty_modal').css('pointer-events', 'none'); */
        if (!modalShown && modal) {
            setTimeout(() => {
                $('#loyaltyModal').modal('show');
                $('html').addClass('overflow-hidden');
                $('#myElement').css('pointer-events', 'auto');
            }, 5000); // timeoutValue

        // Rimuove la classe quando il modal viene chiuso
            $('#loyaltyModal').on('hidden.bs.modal', () => {
                $('.experience-layouts-loyalty_modal').css('pointer-events', 'none');
                localStorage.setItem('modalShown', 'true');
                $('html').removeClass('overflow-hidden');
            });

        // Close Modal click on overlay
            window.onclick = function (event) {
                if (event.target === overlayModal[0]) {
                    modal.style.display = 'none';
                    $('.experience-layouts-loyalty_modal').css('pointer-events', 'none');
                    localStorage.setItem('modalShown', 'true');
                    $('html').removeClass('overflow-hidden');
                }
            };
        }
    });
}
